import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Atoms
import { Input } from 'fatcat-ui-library/components/Atoms/Input';
import { TextArea } from 'fatcat-ui-library/components/Atoms/TextArea';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Button } from 'fatcat-ui-library/components/Atoms/Button';
import PopupMessage from 'src/components/Molecules/PopupMessage/PopupMessage';
import { trackFB } from 'src/utils/gtag';

const schema = yup.object().shape({
	name: yup.string().required('Requierd field!'),
	email: yup.string().email('Use a valid email address!').required('Requierd field!'),
	subject: yup.string().required('Requierd field!'),
	message: yup.string().required('Requierd field!'),
});

type FormValues = {
	name: string
	email: string
	subject: string
	message: string
}

const Contact: React.FC = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<FormValues>({ resolver: yupResolver(schema) });
	const [isSending, setIsSending] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);

	const onSubmitHandler: SubmitHandler<FormValues> = async (data) => {
		setIsSending(true);
		try {
			await fetch('/api/send-mail', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data),
			}).then((res) => {
				if (!res.ok) {
					throw new Error('Your request could not be processed!');
				}
				setIsSuccess(true);
			});
			trackFB('Contact', 'Contact Form');
			reset();
		} catch (error) {
			console.error(error);
		}
		setIsSending(false);
	};

	return (
		<Flex
			as="form"
			direction="column"
			onSubmit={handleSubmit(onSubmitHandler)}
			noValidate
		>
			<Input
				{...register('name')}
				placeholder="Your name"
				errorMsg={errors.name?.message as string}
				fontWeight="medium"
			/>
			<Input
				{...register('email')}
				type="email"
				placeholder="Your email"
				errorMsg={errors.email?.message as string}
				fontWeight="medium"
			/>
			<Input
				{...register('subject')}
				placeholder="Email Subject"
				errorMsg={errors.subject?.message as string}
				fontWeight="medium"
			/>
			<TextArea
				{...register('message')}
				rows={6}
				placeholder="Message"
				errorMsg={errors.message?.message as string}
				fontWeight="medium"
			/>
			<Button
				type="submit"
				w="fit-content"
				size="small"
			>

				{isSending && 'Sending message...'}
				{!isSending && 'Send message'}
			</Button>
			{isSuccess && (
				<PopupMessage
					title="Your message has been sent successfully!"
					description="Our team will contact you shortly."
					setIsActive={setIsSuccess}
				/>
			)}
		</Flex>
	);
};

export default Contact;
