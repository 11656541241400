import React from 'react';
import { HeadFC } from 'gatsby';
import { useTheme } from 'styled-components';

// Components
import HeadMeta from '../components/Molecules/HeadMeta/HeadMeta';
import Contact from '../components/Organisms/Contact/Contact';
import Section from 'fatcat-ui-library/components/Molecules/Section';
import { BR } from 'fatcat-ui-library/components/Atoms/Divider';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';
import { Image } from 'fatcat-ui-library/components/Atoms/Image';
import { Link } from 'fatcat-ui-library/components/Atoms/Link';
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';

// Assets
import plane from 'src/assets/images/plane.svg';

const ContactUs = () => {
	const theme = useTheme();
	return (
		<Wrapper
			as="main"
			background={`linear-gradient(to right, transparent, transparent 50%, ${theme.color.bgLight} 50%, ${theme.color.bgLight})`}
			largeTablet={[{ background: 'transparent' }]}
		>
			<Section
				padding={['t40']}
				largeTablet={[{ padding: ['t40', 'b32'] }]}
				mobile={[{ padding: ['t40'] }]}
			>
				<Flex
					gap="48px"
					largeTablet={['column']}
					mobile={[{ gap: '32px' }]}
				>
					<Flex
						direction="column"
					>
						<Heading
							as="h1"
							padding={['b64']}
							largeTablet={[{ padding: ['b54'] }]}
							mobile={[{ padding: ['b40'] }]}
						>
							Would you like to
							<BR hide="largeTablet" />
							<Text textColor="green">{' get in touch?'}</Text>
						</Heading>
						<Flex
							padding={['b72']}
							largeTablet={[{ padding: ['b54'] }]}
							mobile={[{ padding: ['b32'] }]}
						>
							<Image
								loading="lazy"
								w="235px"
								width="235"
								height="75"
								src={plane}
								alt="Paper plane flying"
							/>
						</Flex>
						<Heading
							as="h3"
							fontWeight="bold"
							padding={['b16']}
						>
							You can also write to us at:
						</Heading>
						<Paragraph>
							<Link display="block" href="mailto:support@enjoygoodhealth.com">
								support@enjoygoodhealth.com
							</Link>
							Enjoy Good Health
							<br />
							15900 La Cantera Pkwy
							<br />
							Ste 20210
							<br />
							San Antonio, TX 78256
							<br />
						</Paragraph>
					</Flex>
					<Flex hide="largeTablet">
						<Contact />
					</Flex>
				</Flex>
			</Section>
			<Section
				backgroundColor="light"
				show="largeTablet"
				padding={['t32', 'b32']}
			>
				<Flex
					direction="column"
				>
					<Contact />
				</Flex>
			</Section>
		</Wrapper>
	);
};

export default ContactUs;

export const Head: HeadFC = (props) => {
	return (
		<HeadMeta
			meta={{
				pageTitle: 'Contact Us | Enjoy good health',
				metaDescription: 'Getting in touch would be great! You can write directly to our founders, Lori Atkins and James Atkins.',
			}}
		/>
	);
};
