import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

import { THeadMeta } from '../../../utils/types';

// Assets
import metaImage from '../../../assets/images/meta/meta.png';

const HeadMeta: React.FC<THeadMeta> = (props) => {
	const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                    description
                }
            }
        }
	`);
	const { href } = useLocation();

	const { meta } = props;
	const { site } = data;

	const pageTitle = meta?.pageTitle || site?.siteMetadata.title;
	const metaDescription = meta?.metaDescription || site?.siteMetadata.description;
	const metaKeywords = meta?.metaKeywords; // eslint-disable-line
	const ogTitle = meta?.ogTitle || pageTitle;
	const ogDescription = meta?.ogDescription || metaDescription;
	const twitterTitle = meta?.twitterTitle || ogTitle;
	const twitterDescription = meta?.twitterDescription || ogDescription;
	const cmsImage = meta?.ogImage?.file.url || meta?.metaImage;
	const ogImage = cmsImage ? `https:${cmsImage}` : `${href}${metaImage}`;
	const twitterImage = meta?.twitterImage?.file.url ? `https:${meta?.twitterImage?.file.url}` : ogImage;
	return (
		<>
			<title>
				{pageTitle}
			</title>
			<meta name="description" content={metaDescription} />
			{metaKeywords && <meta name="keywords" content={metaKeywords} />}
			<meta name="application-name" content={site?.siteMetadata.title} />
			<meta name="viewport" content="width=device-width,initial-scale=1" />
			{meta.isCanonical && <link rel="canonical" href={href} />}

			{/* Fonts */}
			<link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="crossorigin" />
			<link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap" rel="stylesheet" />

			{/* Safari Pinned Tab tags */}
			<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#232323" />

			{/*	Apple Touch Tags */}
			<link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon.png" />

			{/* Facebook Tags */}
			<meta property="og:site_name" content={site?.siteMetadata.title} />
			<meta property="og:type" content="website" />
			<meta property="og:title" content={ogTitle} />
			<meta property="og:description" content={ogDescription} />
			<meta property="og:image:width" content="1200" />
			<meta property="og:image:height" content="630" />
			<meta property="og:image" content={ogImage} />
			<meta property="og:url" content={href} />

			{/* Twitter tags */}
			<meta property="twitter:title" content={`${twitterTitle}`} />
			<meta property="twitter:description" content={twitterDescription} />
			<meta name="twitter:image" content={twitterImage} />

			{/* Windows 8 and 10 Tile tags */}
			<meta name="msapplication-config" content="/browserconfig.xml" />

			{/* Structured Data */}
			{meta.structuredData && (

				<script type="application/ld+json">
					{JSON.stringify(meta.structuredData)}
				</script>
			)}
		</>
	);
};

export default HeadMeta;
